<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 align-items-center">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="groupData.avatar"
          :text="avatarText(groupData.title)"
          :variant="`light-${resolveUserBalanceVariant(groupData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-0">
        {{ groupData.title }}
      </h4>
    </b-media>

    <b-form>
      <b-row>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Назва групи"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="groupData.title"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save"
    >
      Зберегти
    </b-button>

    <small class="text-success ml-1" v-if="saved">Групу збережено</small>
    <small class="text-danger ml-1" v-if="error">Помилка</small>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useGroupsList from '../group-list/useGroupsList'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async save() {

      const response = await axiosIns
        .put(`/business/driver-groups/${this.groupData.id}`, {
          title: this.groupData.title,
          // role: this.userData.role,
        })
        .then(() => {
          this.saved = true
          this.error = false
        })
        .catch(() => {
          this.saved = false
          this.error = true
        })
    },
  },
  setup(props) {

    const toast = useToast()

    const { resolveUserBalanceVariant } = useGroupsList()

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.groupData.avatar = base64
    })

    const saved = false
    const error = false

    return {
      resolveUserBalanceVariant,
      avatarText,
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      saved,
      error,
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
