<template>
  <component :is="groupData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="groupData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-groups-list'}"
        >
          Group List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="groupData"
    >
        <group-edit-tab-account
          :group-data="groupData"
        />
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import groupStoreModule from '../groupStoreModule'
import GroupEditTabAccount from './GroupEditTabAccount.vue'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    GroupEditTabAccount,
  },
  setup() {
    const groupData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-group'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-group/fetchGroup', { id: router.currentRoute.params.id })
      .then(response => { 
        
        let group = response.data.items.filter(group => group.id == router.currentRoute.params.id)[0]
        groupData.value = group
      })
      .catch(error => {
        return false
      })

    return {
      groupData,
    }
  },
}
</script>

<style>

</style>
